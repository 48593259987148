<!--- HEADER -->
<div class="top-nav-bar">
	<div class="row">
		<div class="col-6 left mar-top-10">
			<a class="email mobile blue-color" href="mailto:booking@sapphiremedispa.com">Booking@sapphiremedispa.com</a> | 
			<a class="tel mobile blue-color" href="tel:+13309533350">(330) 953-3350</a>
		</div>
		<div class="col-6 right">
			<ul class="socials">
				<li><a href="https://www.facebook.com/Sapphiremedispa" target="_blank"><span class="facebook-icon"></span></a></li>
				<li><a href="https://www.instagram.com/Sapphiremedispa/" target="_blank"><span class="instagram-icon"></span></a></li>
			</ul>
		</div>
	</div>
	<!--<div class="close-topnavbar"><a href="javascript:;">X</a></div>-->
</div>

<header class="shadow-down js-animate on-canvas">
    <div class="row top grid">
        <div class="phone-holder top-logo mobile-view">
            <div class="mobile-logo"><a href="https://sapphiremedispa.com/"><img src="assets/img/gold-m-lrg.png" alt="Sapphire Aesthetics Los Angeles" class="heade-logo" /></a></div>
        </div>
		<div class="menu-bar">
			<ul>
			  <li><a href="https://sapphiremedispa.com/">Home</a></li>
			  <li><a href="https://sapphiremedispa.com/services/team">About</a></li>
			  <li><a href="https://sapphiremedispa.com/services/">Services</a></li>
			  <li class="logo-menu-itm"><a href="https://sapphiremedispa.com"><img src="assets/img/gold-m-lrg.png" alt="Sapphire Aesthetics Los Angeles" class="heade-logo" /></a></li>
			  <li><a href="https://sapphiremedispa.com/services/contact">Contact</a></li>
			  <li><a href="https://sapphiremedispa.com/services/gallery">Gallery</a></li>
			  <li><a href="https://sapphiremedispa.janeapp.com/" target="_blank">Booking</a></li>
			</ul>
		</div>
        <div class="menu-holder clearfix">
            <div class="icon-holder left">
                <div class="icon">
                    <span class="bar first"></span>
                    <span class="bar middle"></span>
                    <span class="bar last"></span>
                </div>
            </div>
        </div>
		<div class="vip-btn"><span class="vip-menu"><a href="https://sapphiremedispa.com/services/vip-programs/">VIP PROGRAMS</a></span></div>
    </div>
</header>
<!-- fixed nav -->
<div class="fixed-nav">
	<div class="nav-wrapper">

	  <div class="main-links">
		<div class="list">
		  <ul class="holder">
			  <li><a href="https://sapphiremedispa.com/services">Home</a></li>
			  <li><a href="https://sapphiremedispa.com/services/team">About</a></li>
			  <li><a href="https://sapphiremedispa.com/services">Services</a></li>
			  <li><a href="https://sapphiremedispa.com/services/contact">Contact</a></li>
			  <li><a href="https://sapphiremedispa.com/services/gallery">Gallery</a></li>
			  <li><a href="https://sapphiremedispa.janeapp.com/" target="_blank">Booking</a></li>
			  <li><a href="https://sapphiremedispa.com/services/vip-programs/">VIP PROGRAMS</a></li>
			  <li><a href="https://sapphiremedispa.com/services/sapphire-policies/">Sapphire Policies</a></li>
		  </ul>
		</div>
	  </div>

  </div>
</div>
<!-- end -->

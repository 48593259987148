import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { UsersModel } from 'src/app/usersModel';
import { Users } from 'src/app/users';
import { FileSelectDirective, FileUploader } from 'ng2-file-upload';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

imports: [FileUploader, FileSelectDirective]
@Injectable({
  providedIn: 'root'
})

export class AdminService {
  //baseUrl = 'http://3.16.216.67:8081/';
  baseUrl = 'https://sapphiremedispa.com/';
  //baseUrl = 'http://localhost:8082/';
  constructor(private http:HttpClient) { }

  acceptGirlsProfile(body:any) {
    console.log('Update: '+body.id);
    return this.http.post(this.baseUrl +'acceptUpdateGirls', body, {
      observe:'body',
      headers:new HttpHeaders().append("Content-Type", "application/json")
    });
  }
  updateStatus(body:any) {
    console.log('Update: '+body.id);
    return this.http.post(this.baseUrl +'updateStatus', body, {
      observe:'body',
      headers:new HttpHeaders().append("Content-Type", "application/json")
    });
  }
  
  postTotalVideoView(body:any) {
    //console.log('ID:- '+ body.id);
    return this.http.post<any>(this.baseUrl +'countVideo', body).pipe(
      catchError(this.handleError)
    );
  }
  postMyVideoWatch(body:any) {
    //console.log('ID:- '+ body.id);
    return this.http.post<any>(this.baseUrl +'myVideoWatch', body).pipe(
      catchError(this.handleError)
    );
  }
  postVideoComment(body:any) {
    //console.log('ID:- '+ body.id);
    return this.http.post<any>(this.baseUrl +'postVideoComment', body).pipe(
      catchError(this.handleError)
    );
  }
  postVideoLike(body:any) {
    //console.log('ID:- '+ body.id);
    return this.http.post<any>(this.baseUrl +'postVideoLike', body).pipe(
      catchError(this.handleError)
    );
  }
  postVideoUnlike(body:any) {
    //console.log('ID:- '+ body.id);
    return this.http.post<any>(this.baseUrl +'postVideoUnlike', body).pipe(
      catchError(this.handleError)
    );
  }
  postStreamingCategory(body:any) {
    //console.log('ID:- '+ body.id);
    if(body.id == null) {
      return this.http.post(this.baseUrl +'addStreamingCat', body, {
        observe:'body',
        headers:new HttpHeaders().append("Content-Type", "application/json")
      });
    } else {
      return this.http.post(this.baseUrl +'updateStreamingCat', body, {
        observe:'body',
        headers:new HttpHeaders().append("Content-Type", "application/json")
      });
    }
  }
  postStreaming(body:any) {
    //console.log('ID:- '+ body.id);
    if(body.id != '') {
      return this.http.post(this.baseUrl +'updateStreaming', body, {
        observe:'body',
        headers:new HttpHeaders().append("Content-Type", "application/json")
      });
    } else {
      return this.http.post(this.baseUrl +'addStreaming', body, {
        observe:'body',
        headers:new HttpHeaders().append("Content-Type", "application/json")
      });
    }
  }
  postPackage(body:any) {
    if(body.id != '') {
      return this.http.post(this.baseUrl +'updatePackage', body, {
        observe:'body',
        headers:new HttpHeaders().append("Content-Type", "application/json")
      });
    } else {
      return this.http.post(this.baseUrl +'addPackage', body, {
        observe:'body',
        headers:new HttpHeaders().append("Content-Type", "application/json")
      });
    }
  }
  postVideo(body:any) {
    return this.http.post<any>(this.baseUrl +'addVideo', body).pipe(
      catchError(this.handleError)
    );
  }
  postBlog(body:any) {
    return this.http.post<any>(this.baseUrl +'addBlog', body).pipe(
      catchError(this.handleError)
    );
  }
  postPage(body:any) {
    return this.http.post<any>(this.baseUrl +'addPage', body).pipe(
      catchError(this.handleError)
    );
  }
  editUserProfile(body:any) {
    return this.http.post<any>(this.baseUrl +'editUserProfile', body).pipe(
      catchError(this.handleError)
    );
  }
  editGirlsProfile(body:any) {
    return this.http.post<any>(this.baseUrl +'editGirlsProfile', body).pipe(
      catchError(this.handleError)
    );
  }
  post(url:any, data:any){
    let formData = new FormData();
    for(let field in data){
      formData.append(field, data[field]);
    }
    return this.http.post(this.baseUrl + url, formData);
  }

  get(url:any){
    //alert(this.baseUrl + url);
    return this.http.get(this.baseUrl + url);
  }

  delete(url:any){
    //alert(this.baseUrl + url);
    return this.http.get(this.baseUrl + url);
  }

  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}

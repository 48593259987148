import { Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClient, HttpErrorResponse, HttpClientModule, HttpHeaders } from '@angular/common/http';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@Injectable()

export class UserService {
  //baseUrl = 'http://3.16.216.67:8081/';
  baseUrl = 'https://sapphiremedispa.com/';
  webUrl = 'https://sapphiremedispa.com/wp/';
  //webUrl = 'http://localhost:4200/';
  //baseUrl = 'http://localhost:8082/';
  constructor(private _http:HttpClient) { }

  socialLogin(body:any) {
    /*return this._http.post(this.baseUrl +'users/socialLogin', body, {
      observe:'body',
      headers:new HttpHeaders().append("Content-Type", "application/json")
    });*/
    return this._http.post<any>(this.baseUrl +'users/socialLogin', body).pipe(
      //catchError(this.handleError)
    );
  }
  postContactAPI(body:any) {
    return this._http.post<any>(this.baseUrl +'contactFormApi.php', body).pipe(
      catchError(this.handleError)
    );
  }
  /*dashboard(body:any) {
    return this._http.get<any>(this.baseUrl +'users/dashboard', {
      observe:'body',
      headers:new HttpHeaders().append("Content-Type", "application/json")
    });
  } */
  private handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.log(error);
      //console.error(`Backend returned code ${error.status}, ` + `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError('Something bad happened. Please try again later.');
  }
}

<footer class="container">
    <div class="footer-nav">
      <div class="footer-socials hide-for-medium-down"></div>
      <div class="inner">
        <div class="nav">
            <div class="links">
              <ul>
                <li><a href="https://sapphiremedispa.com/">Home</a></li>
                <li><a href="https://sapphiremedispa.com/services/team">About</a></li>
                <li><a href="https://sapphiremedispa.com/services">Services</a></li>
                <li><a href="https://sapphiremedispa.com/services/contact">Contact</a></li>
                <li><a href="https://sapphiremedispa.com/services/gallery">Gallery</a></li>
                <li><a href="https://sapphiremedispa.janeapp.com/" target="_blank">Booking</a></li>
              </ul>
            </div>
  
        </div>
        <!--<div class="divider"></div>-->
      </div>
      
    </div>
    <div class="pb-20 pt-30">
        <div class="google-map">
			<iframe class="responsive-iframe" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3010.0517988345623!2d-80.68797168474802!3d41.02412267929922!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8833fba8297a2025%3A0x2d42eb3d1ca4bdf0!2s755%20Boardman-Canfield%20Rd%20b%206%2C%20Boardman%2C%20OH%2044512%2C%20USA!5e0!3m2!1sen!2sin!4v1656866054308!5m2!1sen!2sin&t=&z=14&ie=UTF8&iwloc=B&output=embed" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
    </div>
    <div class="row trademark pl-10 pr-10 text-center-tablet">
      <div class="text-center">
        <p class="bold">(330) 953-3350</p>
        <p class="bold">Sapphire Aesthetics. All Rights Reserved. <a href="https://southbaycoders.com/" target="_blank" class="block-tablet-down bold">Web design by South Bay Coders</a></p>
      </div>
      <div class="mt-10 text-center">
        <ul>
          <li><a href="https://sapphiremedispa.com/services/sapphire-policies/">Sapphire Policies</a></li>
        </ul>
        <p class="disclaimer">*The content/images on this website are not a guarantee of individual results. Individual results may vary. The information provided on this site is for general informational purposes only, and does not replace the need for a formal consultation with a plastic and reconstructive surgeon before undergoing a surgical procedure or skin care treatment.</p>
      </div>
      
      <!--<div class="footer-phone">
        <a class="star-footer" href="" target="_blank">
          <span class="stars" title="5.0 out of 5 stars"> 4.8 Stars 51 Reviews </span>
          <span class="icon icon-star-gold-small"></span>
          <span class="icon icon-star-gold-small"></span>
          <span class="icon icon-star-gold-small"></span>
          <span class="icon icon-star-gold-small"></span>
          <span class="icon icon-star-gold-small"></span>
        </a>
      </div>-->
  
      <div class="footer-solcial-section footer-socials">
          <ul class="socials mb-0">
              <li><a href="https://www.facebook.com/Sapphiremedispa" target="_blank"><span class="facebook-icon"></span></a></li>
              <li><a href="https://www.instagram.com/Sapphiremedispa/" target="_blank"><span class="instagram-icon"></span></a></li>
          </ul>
          
      </div>
      
    </div>
  
  </footer>
  
  <div class="fixed-cta clearfix">
    <a href="https://sapphiremedispa.com/services/contact/" class="btn">Contact Us</a>
    <a class="tel ppc-href btn" href="tel:+13309533350"><span class="ppc-number">(330) 953-3350</span></a>
  </div>
  
<app-header></app-header>

<main class="content-wrap on-canvas">

    <div class="slider-wrap container main-slider-wrap">
        <div class="main-slider cycle-slideshow" data-cycle-slides="> .slide" data-cycle-timeout="4000" data-cycle-speed="1000" data-cycle-fx="fadeout"
           data-cycle-pager="#no-template-pager"
           data-cycle-pager-template="">
        <div class="container one slide">
          <div class="row">
              <div class="text text-center">
                  <img src="assets/img/logo.png" alt="" class="slider-logo wow fadeIn" data-wow-duration="1s" data-wow-delay="1s">
              </div>
          </div>
        </div>
          <span class="down-arrow wow fadeIn" data-wow-duration="1s" data-wow-delay="1.5s"><img src="assets/img/down-arrow.png" alt=""></span>
        </div>
    </div>

    <div class="container">
        <div class="row gradient-bg general mw-1920">
            <div class="box text-center center-box">
                <h1 class="white-color wow fadeInDown">WELCOME TO<br /><span class="mt-20 display-inline"><img src="assets/img/gold-m-lrg.png" class="welcome-img" alt="Best Sapphire Aesthetics" /></span></h1>
                <h3 class="white-color wow fadeInUp">A MODERN, INNOVATIVE SPACE</h3>
                <div class="divider"></div>
                <div class="wow fadeInUp center-box">
                    <p>At Sapphire Aesthetics and Wellness, it is our philosophy to care for clients using a holistic approach by offering services that are all encompassing of health, beauty, and wellness. We provide various result driven treatments and services aimed to ensure clients leave feeling like a Gem. The Sapphire Experience is designed to help clients meet their health, beauty and wellness goals. All services at Sapphire Aesthetics and Wellness are provided with excellence to assure each of our clients leave feeling confident.</p>
                </div>
            </div>
            <span class="woman-hat">
                <img src="assets/img/woman-black-hat.png" alt="Sapphire Aesthetics Los Angeles">
            </span>
            <!--<span class="gray-m wow fadeIn rellax" data-rellax-speed="-3">
                <img src="assets/img/gray-m.png" alt="Sapphire Aesthetics Redondo Beach">
            </span>-->
        </div>
        
        <div class="services-section">
            <div class="row pt-70 pb-100">
                <h1 class="text-center wow fadeInDown">PATIENT FAVORITES</h1>
                <div class="gallery-index clearfix">
                    <div class="group wow fadeIn">
                        <div class="text-center service-img-section"><a href="javascript:;" class="servicePopUp media" data-id="34">
                            <img src="https://sapphiremedispa.com/services/wp-content/uploads/2022/06/services-1.jpg" alt="Services" class="large">
                        </a></div>
                        <h3 class="small mt-10"><a href="javascript:;" class="servicePopUp media" data-id="34">EmSella</a></h3>
                        <div class="divider"></div>
                        <div class="service-detail">No one likes to talk about it, but incontinence is something that affects millions of men and women daily! Sapphire Aesthetics and Wellness is proud to offer a solution for incontinence to our clients by offering Emcella treatments! This technology is noninvasive and will strengthen your pelvic floor muscles like never before. In a short 28-minute treatment, our machine will contract the pelvic floor muscles in result strengthening it! This painless treatment is equivalent to THOUSANDS of kegels in 28 minutes. Benefits go beyond urinary incontinence, this treatment will also improve blood flow to the pelvic floor making it a GREAT treatment to also improve sexual health! Come in for a consultation today to learn more about this life changing treatment!</div>
                    </div>
                    <div class="group wow fadeIn">
                        <div class="text-center service-img-section"><a href="javascript:;" class="servicePopUp media" data-id="33">
                            <img src="https://sapphiremedispa.com/services/wp-content/uploads/2022/06/services-2.jpg" alt="Services" class="large">
                        </a></div>
                        <h3 class="small mt-10"><a href="javascript:;" class="servicePopUp media" data-id="33">EmSculpt NEO</a></h3>
                        <div class="divider"></div>
                        <div class="service-detail">Want to lose fat AND gain muscle? At Sapphire Aesthetics and Wellness, we are proud to have the best technology to help you reach your body goals with no anesthesia or downtime with a noninvasive treatment. The EmSculpt combines heat and muscle contracting technology that is equivalent to doing 20,000 crunches or squats in 30 minutes! This machine can treat arms, abs, inner thighs, outer thighs, butt, and claves! Let us help you reach your body goals. A series of treatments are required. Call today for a consultation to learn more about this treatment!</div>
                    </div>
                    <div class="group wow fadeIn">
                        <div class="text-center service-img-section"><a href="javascript:;" class="servicePopUp media" data-id="32">
                            <img src="https://sapphiremedispa.com/services/wp-content/uploads/2022/06/shutterstock_1765319396.jpg" alt="Services" class="large">
                        </a></div>
                        <h3 class="small mt-10"><a href="javascript:;" class="servicePopUp media" data-id="32">IV fluid infusions</a></h3>
                        <div class="divider"></div>
                        <div class="service-detail">Hydration! Hydration! Hydration! We offer various types of IV fluids to help you achieve complete rehydration! Come relax and recline in our drip lounge to get hydrated. IV fluids can improve energy, focus, elevate those hang-over symptoms, provide immune support and restore vitamin levels. Boosts are also available to be added to you IV fluid experience. Call today for a consultation.</div>
                    </div>
    
                </div>
            </div>
        </div>
        
        <div class="dark-section">
            <div class="row mw-1280 s3grid">
                <div class="feat2 lt">
                    <h2 class="wow fadeInLeft">Why Sapphire?</h2>
                    <p><strong>Sapphire is a symbol of the heavens, a guardian of innocence, bestower of truth, a promoter of good health and preserver of chastity. It is believed to bring gifts of fulfillment,  joy, prosperity, inner peace and beauty.</strong></p>
                </div>
                <div class="feat2 rt">
                    <h3>Sate of the Art FDA Approved Technology</h3>
                    <ul class="blue-color">
                        <li><a href="javascript:;">- Emsculpt Neo</a></li>
                        <li><a href="javascript:;">- EmSella</a></li>
                        <li><a href="javascript:;">- Emtone</a></li>
                        <li><a href="javascript:;">- Hydrafcial</a></li>
                        <li><a href="javascript:;">- SkinPen</a></li>
                    </ul>
                </div>
            </div>
            <!--<span class="gold-m rellax" data-rellax-speed="-3">
                <img src="assets/img/gold-m-sml.png" alt="Sapphire Aesthetics Redondo Beach">
            </span>-->
        </div>
        <div class="quote row">
			<h2>A Notch Above Standard: <span>I invite you to come have a Sapphire Experience, where Everyone is Treated Like a Gem</span></h2>
			<span class="sig">- Ashley Vidale, CEO</span>
		</div>
            <div class="utherapy-elation clearfix">
                <div class="white-m hide-for-small">
                    <img src="assets/img/white-m.png" alt="Sapphire Aesthetics Manhattan Beach" />
                </div>
                <div class="row s3grid">
                    <div class="feat2">
                        <img src="assets/img/homepage-lady-left.jpg" alt="Sapphire Aesthetics Manhattan Beach" class="hide-for-small">
                        <img src="assets/img/homepage-lady-left.jpg" alt="Sapphire Aesthetics Torrance" class="show-for-small">
                        <div class="box black-color">
                            <h3 class="wow fadeInUp"><a href="javascript:;">BOTOX</a></h3>
                            <p class="wow fadeInDown">Botox is known and an anti-aging treatment. It is a purified protein medication that is injected into the muscles to relax fine lines and wrinkles caused from expressive facial movements such as smiling, laughing, and squinting. Botox can also be injected to give an eyebrow lift and a lip flip for a poutier appearance. Common treatment areas include crow’s feet, pebble chin, forehead lines, the “angry 11s” and gummy smile just name a few.</p>
                            <a href="javascript:;" data-id="boxtox_img" class="modal-toggle square boxtox_img">BOTOX</a>
                        </div>
                    </div>
                    <div class="feat2">
                        <img src="assets/img/homepage-lady-right.jpg" alt="Sapphire Aesthetics Torrance" class="hide-for-small">
                        <img src="assets/img/homepage-lady-right-sml.jpg" alt="Sapphire Aesthetics Palos Verdes" class="show-for-small">
                        <div class="box">
                            <h3 class="wow fadeInUp"><a href="javascript:;">Facial Fillers</a></h3>
                            <p class="wow fadeInDown">The most common fillers are hyaluronic acid gels and there also occur naturally in the body. Fillers are injected beneath the skin to add volume and fullness to the treated area. Fillers can sculpt the jawline and chin, plump the lips and cheeks, also with decreasing that appearance of laugh lines and wrinkles. Fillers are a great treatment option to leave the face looking more balanced, youthful and refreshed.</p>
                            <a href="javascript:;" data-id="facial_img" class="modal-toggle square">Facial Fillers</a>
                        </div>
                    </div>
                </div>
            </div>
            <div class="tri-images2">

                <div class="mid">
                    <div class="owl-carousel home-slider">
                        <div class="item">
                            <div class="box">
                                <h2>Kybella</h2>
                                <p>Kybella is the first FDA-approved non-surgical treatment to get rid of submental fullness also known as the double chin. It is an injectable that improves the appearance of double chins by destroying fat cells. Treatment with Kybella is fast, requires little downtime, and destroys fat cells permanently. Kybella is a great treatment option to address facial profile concerns and is tailored to each client. Define your chin with a Kybella treatment today.</p>
                            </div>
                            <a href="https://sapphiremedispa.com/services/" class="square">Kybella</a>
                        </div>
                        <!--<div class="item">
                            <div class="box">
                                <h2>The CoolMini&reg;</h2>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. </p>
                            </div>
                            <a href="javascript:;" class="square">CoolMini</a>
                        </div>-->
                    </div>
                </div>
            </div>
		
        <div class="staggered-image">
            <div class="row">
                <div class="media">
                    <div class="image">
                        <img src="assets/img/woman-profile.jpg" alt="Los Angeles Sapphire Aesthetics" />
						<h2>Medical Aesthetician Services</h2>
                    </div>
                    <div class="box">
                        <p>Sapphire Aesthetics and Wellness is a Medical Spa performing medical procedures to help clients reach their aesthetic goal. Our focus is on conditions such as cellulite, skin laxity, excessive fat, acne scars, thinning hair, hyperpigmentation, acne, dehydration, and overall skin health. All treatments are performed by licensed and trained medical professionals. Our staff is well trained and certified to safely and effectively treat our clients. We are here to help you reach your aesthetic goals with FDA approved procedures and treatments. Come have a Sapphire Experience, where everyone is treated like a Gem.</p>
                        <a href="javascript:;" class="gotoSchedule square">Learn More</a>
                    </div>
                </div>
                <h3 class="wow fadeInUp">A Cut Above The Rest.</h3>
            </div>
            <span class="gold-m rellax" data-rellax-speed="-3">
                <img src="assets/img/gold-m-lrg.png" alt="Redondo Beach Sapphire Aesthetics">
            </span>
        </div>

    </div>
<app-newsletter></app-newsletter>
<app-footer></app-footer>

</main> 